<template>
    <div class="pastor">
        <div class="text">{{i + 1}}</div>
        <div class="text">{{pastor.name}}</div>
        <div class="text">{{pastor.whatsapp}}</div>
        <div class="text">{{pastor.telegram}}</div>
        <div class="text">{{pastor.email}}</div>
        <div class="option">
            <div @click="option = !option">
                <optionicon :size="'20px'"/>
            </div>
            <optionbox v-if="option" 
                @edit="edit=true; option = false"
                @del="del=true; option = false"/>
        </div>
        <editbox v-if="edit" @close="edit = false" :pastor="pastor" @edited="edited($event)"/>
        <deletebox v-if="del" @close="del = false" @remove="remove($event)" 
            :name="pastor.name" :id="pastor.id"/>
    </div>
</template>

<script>
import optionicon from '../../icons/option.vue'
import optionbox from './option.vue'

import editbox from './edit.vue'
import deletebox from './delete.vue'

export default {
    components: {
        optionicon,
        optionbox,
        editbox, deletebox
    },
    props: ['pastor', 'i'],
    data() {
        return {
            option: false,
            edit: false,
            del: false
        }
    },
    methods: {
        remove(what) {
            
            this.$emit('removed', what)
            this.del = false
        },
        edited(e) {

            this.$emit('edited', e)
            this.edit = false
        }
    }
}
</script>

<style scoped>

    .pastor {
        display: grid;
        grid-template-columns: 10% 20% 20% 20% 25% 5%;
        border-top: 1px solid #ccc;
        padding: 15px 20px;
    }
    .option {
        display: flex;
        width: 100%;
        justify-content: center;
        cursor: pointer;
        position: relative;
    }
</style>