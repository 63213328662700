<template>
    <div class="dashboard">
        <div class="title">
            Dashboard
        </div>
        <loader class="loader" v-if="loading"/>
        <div class="grid" v-else>
            <div class="item">
                <div>
                    <div class="no">{{pastors}}</div>
                    <div class="name">Pastors</div>
                </div>
                <div>
                    <pastors :size="'30px'" :style="{'height': '30px'}"/>
                </div>
            </div>
            <div class="item">
                <div>
                    <div class="no">{{events}}</div>
                    <div class="name">Events</div>
                </div>
                <div>
                    <calendar :size="'30px'" :style="{'height': '30px'}"/>
                </div>
            </div>
            <div class="item">
                <div>
                    <div class="no">{{broadcasts}}</div>
                    <div class="name">Broadcasts</div>
                </div>
                <div>
                    <broadcast :size="'30px'" :style="{'height': '30px'}"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import loader from '../../components/loader.vue'
import pastors from '../../icons/pastors.vue'
import calendar from '../../icons/calendar.vue'
import broadcast from '../../icons/broadcast.vue'

export default {
    components: {
        loader,
        pastors, calendar, broadcast
    },
    data() {
        return {
            loading: true,
            pastors: 0,
            events: 0,
            broadcasts: 0
        }
    },
    mounted() {
        this.$http.get('/dashboard')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.pastors = r.p
                this.events = r.e
                this.broadcasts = r.b
            }
            this.loading = false
        })
    }
}
</script>

<style scoped>
    .dashboard {
        padding: 30px;
    }
    .title {
        font-size: 25px;
        font-weight: 500;
    }

    .loader {
        margin-top: 70px;
    }

    .grid {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 30px;
    }
    .item {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        fill: var(--main);
    }
    .no {
        font-size: 20px;
        font-weight: 500;
        color: var(--main);
    }
    .name {
        font-weight: 500;
        color: gray;
        font-size: 14px;
    }
</style>