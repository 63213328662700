<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Add Pastors</div>

            <loader v-if="fetching"/>

            <div v-else>
                <div class="pastors">
                    <div class="pastor" v-for="(pastor, i) in pastors" :key="i">
                        <div class="circle" @click="select(i)">
                            <div class="c" v-if="pastor.clicked"></div>
                        </div>
                        <div class="name">{{pastor.name}}</div>
                    </div>
                </div>
                <loading :height="'37px'" v-if="loading"/>
                <div class="btn" v-else @click="add()">Add</div>
            </div>
        </div>
    </div>
</template>

<script>
import loader from '../loader.vue'
import loading from '../loading.vue'

export default {
    components: {
        loader, loading
    },
    props: ['id', 'old'],
    data() {
        return {
            fetching: true,
            loading: false,
            pastors: [],
            selected: []
        }
    },
    mounted() {
        this.$http.get('/pastors/get')
        .then(data => {
            let r = data.data

            if (r.success) {
                let pastors = []

                let x
                for (x of r.pastors) {
                    let y
                    let seen = false
                    for (y of this.old) {
                        if (y.id == x.id) { seen = true }
                    }
                    if (!seen) {
                        pastors.push({
                            'name': x.name,
                            'id': x.id,
                            'clicked': false
                        })
                    }
                }

                this.pastors = pastors
                this.fetching = false
            }
        })
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        select(what) {
            let c = this.pastors[what].clicked

            if (c) {
                this.pastors[what].clicked = false

                this.selected = this.selected.filter(value => {
                    return value != this.pastors[what].id
                })
            } else {
                this.pastors[what].clicked = true
                this.selected.push(this.pastors[what].id)
            }
        },
        add() {
            if (this.selected.length == 0) {
                this.$toast.warning('Select at least one pastor', ''); return
            }

            let form = new FormData()
            form.append('id', this.id)
            form.append('ids', JSON.stringify(this.selected))

            this.loading = true

            this.$http.post('/broadcast/pastors/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    
                    this.$toast.success('pastors has been added', 'Success', this.$root.$data.success)

                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                }
            })
        }
    }
}
</script>

<style scoped>
    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        background-color: white;
        border-radius: 5px;
        width: 300px;
        position: relative;
        z-index: 3;
        padding: 20px;
        animation: pop .3s;
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .pastors {
        max-height: 60vh;
        overflow: auto;
    }
    .pastor {
        display: flex;
        align-items: center;
        gap: 20px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--main);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .c {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--main);
    }
    .btn {
        padding: 8px;
        text-align: center;
        background-color: var(--main);
        cursor: pointer;
        color: white;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
    }
</style>