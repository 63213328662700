<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Add a template</div>
            <div class="input">
                <div class="label" v-if="label != ''">Label</div>
                <input placeholder="Enter template label" v-model="label"/>
            </div>
            <div class="input">
                <div class="label" v-if="content != ''">Content</div>
                <textarea placeholder="Enter template content" v-model="content"></textarea>
            </div>
            <loading :height="'37px'" v-if="loading"/>
            <div class="btn" @click="add" v-else>Add</div>
        </div>
    </div>  
</template>

<script>
import loading from '../loading.vue';

export default {
    components: {
        loading
    },
    data() {
        return {
            label: '',
            content: '',
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        add() {
            if (this.label == '' || this.content == '') {
                this.$toast.warning('Make sure all field are completed', '');
                return
            }

            this.loading = true
            
            let form = new FormData()
            form.append('label', this.label)
            form.append('content', this.content)

            this.$http.post('/template/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    let template = {
                        'label': this.label,
                        'content': this.content,
                        'id': r.id
                    }
                    
                    this.$toast.success('A new template has been added', 'Added', this.$root.$data.success)

                    this.$emit('new_template', template)
                    this.loading = false
                }
            })
        }
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        background-color: white;
        border-radius: 5px;
        width: 300px;
        position: relative;
        z-index: 3;
        padding: 20px;
        animation: pop .3s;
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        font-weight: 500;
        font-size: 14px;
        animation: up .2s;
    }
    @keyframes up {
        from {
            top: 10px;
            opacity: 0;
        }
        to {
            top: -10px;
            opacity: 1;
        }
    }
    input, textarea {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    textarea {
        height: 150px;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }
</style>