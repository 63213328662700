<template>
    <div class="view">
        <div class="flex">
            <div class="no">{{i + 1}}</div>
            <div class="label" @click="use()">{{template.label}}</div>
            <div class="icon" @click="show_opt = !show_opt">
                <optionicon :size="'20px'"/>
            </div>
            <div class="opt" v-if="show_opt">
                <optionbox
                    @edit="edit=true; show_opt = false"
                    @del="del=true; show_opt = false"/>
            </div>
        </div>

        <editbox @close="edit = false" :template="template" v-if="edit"/>
        <deletebox @close="del = false" :id="template.id" :label="template.label" v-if="del"/>
    </div>
</template>

<script>
import optionicon from '../../icons/option.vue';
import optionbox from './option.vue';

import editbox from './edit.vue';
import deletebox from './delete.vue';

export default {
    components: {
        optionicon, optionbox,
        editbox, deletebox
    },
    props: ['template', 'i'],
    data() {
        return {
            show_opt: false,
            edit: false,
            del: false,
        }
    },
    methods: {
        use() {
            this.$emit('use', this.template.content)
        }
    }
}
</script>

<style scoped>
    .flex {
        padding: 10px 20px;
        gap: 15px;
        display: flex;
        align-items: center;
        border-top: 1px solid #ccc;
        font-size: 14px;
        position: relative;
    }
    .no {
    }
    .label {
        font-weight: 500;
        cursor: pointer;
    }
    .icon { 
        margin-left: auto;
        fill: #555;
        cursor: pointer;
    }

    .opt {
        position: absolute;
        right: 10px;
        top: 10px;
    }
</style>