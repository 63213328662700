<template>
    <div class="send">
        <div class="head">
            <div class="title">
                Compose Message
            </div>
        </div>

        <loader class="l" v-if="loading"/>

        <div class="main" v-else>
            <div>
                <div class="box">
                    <div class="t">Broadcasts</div>

                    <div class="empty" v-if="!loading && broadcasts.length == 0">
                        <broadcast :size="'50px'"/>
                        <div class="e_text">No broadcast has been added to this database</div>
                    </div>

                    <div class="item" v-for="(broadcast, i) in broadcasts" :key="i">
                        <div class="circle" @click="toggleBroadcast(!broadcast.clicked, i)">
                            <div class="c" v-if="broadcast.clicked"></div>
                        </div>
                        <div class="name">{{broadcast.name}}</div>
                        <div class="no">{{broadcast.total}}</div>
                    </div>
                </div>

                <div class="box">
                    <div class="template_head">
                        <div class="t">Templates</div>
                        <div class="add" @click="add_tem = true">
                            <add :size="'25px'"/>
                        </div>
                    </div>
                    <div v-if="fetching">
                        <loader class="template_l"/>
                    </div>
                    <div class="empty" v-if="!fetching && templates.length == 0">
                        <dashboard :size="'30px'"/>
                        <div class="e_text">No template has been added</div>
                    </div>

                    <div>
                        <templateview v-for="(template, i) in templates" @use="content = $event"
                            :key="template.id" :template="template" :i="i"/>
                    </div>
                </div>
            </div>

            <div class="input">
                <quill-editor
                    v-model="content"
                    :options="editorOption"
                />
                <div class="flex">
                    <div class="btn" @click="send_whatsapp()">Whatsapp</div>
                    <!-- <div class="btn">Telegram</div>
                    <div class="btn">Both</div> -->
                </div>
            </div>
        </div>

        <templateadd v-if="add_tem" @close="add_tem = false" @new_template="new_template($event)"/>
    </div>
</template>

<script>
import loader from '../../components/loader.vue'
import broadcast from '../../icons/broadcast.vue'

import add from '../../icons/add.vue'
import templateview from '../../components/template/view.vue'
import templateadd from '../../components/template/add.vue'
import dashboard from '../../icons/dashboard.vue'

import TurndownService from 'turndown'

export default {
    components: {
        loader, broadcast,
        add, templateview, templateadd, dashboard
    },
    mounted() {
        this.$http.get('/broadcasts')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.broadcasts = r.broadcasts
                this.loading = false
            }
        })

        this.$http.get('/templates/all')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.templates = r.templates
                this.fetching = false
            }
        })
    },
    data() {
        return {
            loading: true,
            broadcasts: [],
            editorOption: {
                modules: {
                    toolbar: [
                        'bold', 'italic', 'strike', 'clean'
                    ]
                },
                placeholder: 'Compose a message',
                readOnly: false,
                theme: 'snow'
            },
            content: '',

            add_tem: false,
            fetching: true,
            templates: []
        }
    },
    methods: {
        toggleBroadcast(to, i) {
            console.log(to, i);
            this.broadcasts[i]['clicked'] = to
        },
        new_template(e) {
            this.templates.push(e)
            this.add_tem = false
        },
        send_whatsapp() {
            if (this.content == '') {
                this.$toast.warning('message cannot be empty', '');
                return
            }

            let ids = []
            let x
            for (x of this.broadcasts) {
                if (x.clicked) {
                    ids.push(x.id)
                }
            }

            if (ids.length == 0) {
                this.$toast.warning('select a broadcast', '');
                return
            }
            
            var turndownService = new TurndownService({ strongDelimiter: '*'})
            var markdown = turndownService.turndown(this.content)

            console.log(markdown);


            let form = new FormData()
            form.append('ids', JSON.stringify(ids))
            form.append('message', markdown)

            this.$http.post('/send/whatsapp', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    console.log(r);
                }
            })

            this.content = ''
            
            this.$toast.success('Check whatsapp for confirmation', 'Sent', this.$root.$data.success)
        }
    }
}
</script>

<style scoped>

    .send {
        padding: 30px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-size: 25px;
        font-weight: 500;
    }

    .l {
        margin-top: 150px;
    }
    .main {
        display: grid;
        grid-template-columns: 300px auto;
        gap: 30px;
        margin-top: 30px;
    }
    .box {
        background-color: white;
        border-radius: 5px;
        height: max-content;
        margin-bottom: 30px;
    }
    
    .empty {
        text-align: center;
        fill: #555;
        padding: 40px 20px;
        font-size: 14px;
        color: gray;
    }
    .t {
        padding: 20px;
        font-weight: 500;
    }
    .item {
        display: flex;
        padding: 10px 20px;
        align-items: center;
        gap: 20px;
        border-top: 1px solid #ddd;
    }
    .circle {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        border: 2px solid var(--main);
        cursor: pointer;
        transition: background-color .2s;
    }
    .circle:hover {
        background-color: var(--trans);
    }
    .c {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--main);
    }
    .name {
        font-weight: 500;
        color: gray;
    }
    .no {
        
        font-weight: 500;
        background-color: var(--main);
        padding: 5px 8px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: white;
        margin-left: auto;
        font-size: 12px;
    }


    .template_head {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        fill: var(--main)
    }
    .template_head .t {
        padding: 0px;
    }
    .template_head .add {
        cursor: pointer;
    }
    .template_l {
        padding: 20px 0px;
    }

    .input {
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
        height: max-content;
    }
    .flex {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        border-top: 1px solid #ddd;
        padding: 20px;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
    }
    

    @media screen and (max-width: 750px) {
        .main {
            grid-template-columns: auto;
        }
    }
</style>