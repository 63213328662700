<template>
    <div>
        <svg :height="size" :width="size" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256C397.4 512 512 397.4 512 256S397.4 0 
            256 0zM352 280H280V352c0 13.2-10.8 24-23.1 24C242.8 376 232 365.2 232 352V280H160C146.8 280 
            136 269.2 136 256c0-13.2 10.8-24 24-24H232V160c0-13.2 10.8-24 24-24C269.2 136 280 146.8 280 
            160v72h72C365.2 232 376 242.8 376 256C376 269.2 365.2 280 352 280z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['size']
}
</script>

<style scoped>

</style>