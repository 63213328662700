<template>
    <div class="optionbox">
        <div class="item" @click="$emit('edit')">
            Edit
            <edit :size="'15px'"/>
        </div>
        <div class="item up" @click="$emit('del')">
            Delete
            <close :size="'15px'"/>
        </div>
    </div>
</template>

<script>
import edit from '../../icons/edit.vue'
import close from '../../icons/delete.vue'

export default {
    components: {
        edit, close
    }
}
</script>

<style scoped>
    .optionbox {
        background-color: white;
        border-radius: 5px;
        width: fit-content;
        position: absolute;
        top: 25px;
        right: 0px;
        /* border: 1px solid #ccc; */
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        overflow: hidden;
        
    }
    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 10px 15px;
        font-size: 12px;
        font-weight: 500;
        width: 80px;
        cursor: pointer;
        transition: .3s;
    }
    .item:hover {
        color: white;
        background-color: var(--main);
        fill: white;
    }
    .up {
        border-top: 1px solid #ddd;
    }

</style>