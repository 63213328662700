<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Create Broadcast</div>
            <div class="input">
                <div class="label" v-if="title != ''">Title</div>
                <input placeholder="Enter broadcast title" v-model="title"/>
            </div>
            <loading v-if="loading" :height="'37px'"/>
            <div class="btn" v-else @click="create()">Create</div>
        </div>
    </div>
</template>

<script>
import loading from '../loading.vue'

export default {
    components: {
        loading
    },
    data() {
        return {
            title: '',
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        create() {
            if (this.title == '') {
                this.$toast.warning('Enter broadcast title', 'Title');
                return
            }

            this.loading = true

            let form = new FormData()
            form.append('name', this.title)

            this.$http.post('/broadcast/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    
                    this.$toast.success(
                        'A new broadcast has been created', 'Created', this.$root.$data.success
                    )

                    this.$emit('created', {
                        'name': this.title,
                        'id': r.id,
                        'total': 0,
                        'pastors': []
                    })
                }
            })
        }
    }
}
</script>

<style scoped>

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        background-color: white;
        border-radius: 5px;
        width: 300px;
        position: relative;
        z-index: 3;
        padding: 20px;
        animation: pop .3s;
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        font-weight: 500;
        font-size: 14px;
        animation: up .2s;
    }
    @keyframes up {
        from {
            top: 10px;
            opacity: 0;
        }
        to {
            top: -10px;
            opacity: 1;
        }
    }
    input {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }
</style>