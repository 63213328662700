import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'

import home from '../views/home.vue'
import dashboard from '../views/home/dashboard.vue'
import pastors from '../views/home/pastors.vue'
import broadcast from '../views/home/broadcast.vue'
import calendar from '../views/home/calendar.vue'
import send from '../views/home/send.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: login
    },
    {
        path: '/home',
        name: 'home',
        component: home,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: dashboard
            },
            {
                path: 'pastors',
                name: 'pastors',
                component: pastors
            },
            {
                path: 'broadcast',
                name: 'broadcast',
                component: broadcast
            },
            {
                path: 'calendar',
                name: 'calendar',
                component: calendar
            },
            {
                path: 'send',
                name: 'send',
                component: send
            }
        ]
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
  routes
})

export default router
