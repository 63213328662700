<template>
    <div>
        <svg :height="size" :width="size" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1582 1.02567C14.4202 1.113 14.5618 1.39616 14.4745 1.65813L13.9745 3.15813C13.8872 
            3.4201 13.604 3.56168 13.342 3.47436C13.0801 3.38703 12.9385 3.10387 13.0258 2.8419L13.5258 
            1.3419C13.6131 1.07993 13.8963 0.938348 14.1582 1.02567ZM17.8545 2.85355C18.0498 2.65829 
            18.0498 2.34171 17.8545 2.14645C17.6593 1.95118 17.3427 1.95118 17.1474 2.14645L15.1474 
            4.14645C14.9522 4.34171 14.9522 4.65829 15.1474 4.85355C15.3427 5.04882 15.6593 5.04882 
            15.8545 4.85355L17.8545 2.85355ZM7.60704 3.14546C8.21072 1.87491 9.90269 1.60806 10.8681 
            2.63114L17.4549 9.61134C18.4032 10.6163 18.0711 12.2572 16.8066 12.8142L12.8695 14.5487C12.9544 
            14.8507 12.9998 15.1692 12.9998 15.4983C12.9998 17.4313 11.4328 18.9983 9.49984 18.9983C8.24407 
            18.9983 7.14276 18.337 6.52522 17.3436L5.3246 17.8725C4.76043 18.1211 4.10165 17.9991 3.66384 
            17.5651L2.44207 16.3539C1.9897 15.9055 1.86989 15.2203 2.14325 14.6449L7.60704 3.14546ZM7.4534 
            16.9347C7.90573 17.578 8.6537 17.9983 9.49984 17.9983C10.8806 17.9983 11.9998 16.8791 11.9998 
            15.4983C11.9998 15.3127 11.9796 15.1318 11.9412 14.9576L7.4534 16.9347ZM17 6C16.7239 6 16.5 
            6.22386 16.5 6.5C16.5 6.77614 16.7239 7 17 7H18.4992C18.7754 7 18.9992 6.77614 18.9992 
            6.5C18.9992 6.22386 18.7754 6 18.4992 6H17Z"/>
        </svg>

        <!-- <svg :height="size" :width="size" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
            <rect fill="none" height="256" width="256"/>
            <circle cx="128" cy="128" r="40"/>
            <path d="M82.7,82.7a7.9,7.9,0,0,0,0-11.3,8,8,0,0,0-11.3,0A80,80,0,0,0,54.3,96.9a79.9,79.9,0,0,0,0,62.2,80,80,0,0,0,17.1,25.5,8.3,8.3,0,0,0,5.7,2.3,8,8,0,0,0,5.6-2.3,7.9,7.9,0,0,0,0-11.3A65.4,65.4,0,0,1,69,152.9a64.5,64.5,0,0,1,0-49.8A65.4,65.4,0,0,1,82.7,82.7Z"/>
            <path d="M208,128a78.6,78.6,0,0,0-6.3-31.1,80,80,0,0,0-17.1-25.5,8,8,0,0,0-11.3,0,7.9,7.9,0,0,0,0,11.3A65.4,65.4,0,0,1,187,103.1a64.5,64.5,0,0,1,0,49.8,65.4,65.4,0,0,1-13.7,20.4,7.9,7.9,0,0,0,0,11.3,8,8,0,0,0,5.6,2.3,8.3,8.3,0,0,0,5.7-2.3,80,80,0,0,0,17.1-25.5A78.6,78.6,0,0,0,208,128Z"/>
            <path d="M32.2,168.5a104.1,104.1,0,0,1,0-81,101.3,101.3,0,0,1,22.3-33A8.1,8.1,0,0,0,43.1,43.1,121.8,121.8,0,0,0,17.4,81.3a120.7,120.7,0,0,0,0,93.4,121.8,121.8,0,0,0,25.7,38.2,8.5,8.5,0,0,0,5.7,2.3,8.3,8.3,0,0,0,5.7-2.3,8.1,8.1,0,0,0,0-11.4A101.3,101.3,0,0,1,32.2,168.5Z"/>
            <path d="M238.6,81.3a121.8,121.8,0,0,0-25.7-38.2,8.1,8.1,0,1,0-11.4,11.4A103.5,103.5,0,0,1,232,128a103.5,103.5,0,0,1-30.5,73.5,8.1,8.1,0,0,0,0,11.4,8.3,8.3,0,0,0,5.7,2.3,8.5,8.5,0,0,0,5.7-2.3,121.8,121.8,0,0,0,25.7-38.2,120.7,120.7,0,0,0,0-93.4Z"/>
        </svg> -->
    </div>
</template>

<script>
export default {
    props: ['size']
}
</script>

<style scoped>

</style>