<template>
    <div>
        <svg :height="size" :width="size" enable-background="new 0 0 24 24" version="1.0" viewBox="0 0 24 24" 
        xml:space="preserve" xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink">
            <g>
                <g>
                    <path d="M16.5,14c-1.5,0-2.7,0.4-3.6,0.9c1.4,1.2,2,2.6,2.1,2.7l0.1,0.2V20h8v-2C23,18,21.4,14,16.5,14z"/>
                </g>
                <g>
                    <circle cx="16.5" cy="8.5" r="3.5"/>
                </g>
            </g>
            <g>
                <path d="M4,8.5C4,6.6,5.6,5,7.5,5S11,6.6,11,8.5c0,1.9-1.6,3.5-3.5,3.5S4,10.4,4,8.5z M7.5,14C2.6,14,1,18,1,18v2h13v-2   C14,18,12.4,14,7.5,14z"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['size']
}
</script>

<style scoped>

</style>