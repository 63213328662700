<template>
    <div class="t">
        <div class="table">
            <div class="h">
                <div class="title">Pastors List</div>
                <div class="search">
                    <input type="text" placeholder="Search pastors">
                </div>
            </div>
            <div class="head">
                <div class="text">No</div>
                <div class="text">Name</div>
                <div class="text">Whatsapp</div>
                <div class="text">Telegram</div>
                <div class="text">Email</div>
                <div class="text"></div>
            </div>
            <loader class="loader" v-if="loading"/>
            <div v-else>
                <pastor v-for="(pastor, i) in pastors" :key="pastor.id" :pastor="pastor" :i="i"
                    @removed="removed($event)" @edited="edited($event, i)"/>
            </div>

            <div class="empty" v-if="!loading && pastors.length == 0">
                <pastorsicon :size="'80px'"/>
                <div class="em_text">No pastor has been added to this database</div>
            </div>
        </div>
    </div>
</template>

<script>
import pastor from './pastor.vue'
import loader from '../loader.vue'

import pastorsicon from '../../icons/pastors.vue'

export default {
    components: {
        pastor,
        loader,
        pastorsicon
    },
    props: ['loading', 'pastors'],
    data() {
        return {
        }
    },
    methods: {
        removed(what) {
            this.$emit('removed', what)
        }, 
        edited(e, i) {
            this.$emit('edited', {
                'data': e,
                'i': i
            })
        }
    }
}
</script>

<style scoped>
    .t {
        width: 100%;
        overflow: auto;
    }
    .table {
        margin-top: 30px;
        background-color: white;
        border-radius: 5px;
        min-width: 900px;
    }
    .h {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: var(--main);
        color: white;
        border-radius: 5px 5px 0px 0px;
    }

    .loader {
        border-top: 1px solid #ccc;
        padding: 100px 0px;
    }
    .title {
        font-size: 18px;
        font-weight: 500;
        /* color: var(--main); */
    }
    .search {
        width: fit-content;
        margin-left: auto;
    }
    input {
        padding: 10px;
        border: 1px solid #ddd;
        border: none;
        border-radius: 5px;
    }

    .head {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 10% 20% 20% 20% 20% 10%;
        /* border-bottom: 1px solid #ccc; */
        padding: 15px 20px;
        font-weight: 500;
    }

    .empty {
        text-align: center;
        fill: #555;
        padding: 80px 0px;
        border-top: 1px solid #ccc;
        font-size: 14px;
        color: gray;
    }
</style>