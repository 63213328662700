<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Add a pastor</div>
            <div class="input">
                <div class="label" v-if="name != ''">Name</div>
                <input placeholder="Enter pastor name" v-model="name"/>
            </div>
            <div class="input">
                <div class="label" v-if="whatsapp != ''">Whatsapp</div>
                <input placeholder="Enter whatsapp number" v-model="whatsapp">
            </div>
            <div class="input">
                <div class="label" v-if="telegram != ''">Telegram</div>
                <input type="text" placeholder="Enter telegram number" v-model="telegram">
            </div>
            <div class="input">
                <div class="label" v-if="email != ''">Email</div>
                <input type="text" placeholder="Enter email address" v-model="email">
            </div>
            <loading :height="'37px'" v-if="loading"/>
            <div class="btn" @click="add" v-else>Add</div>
        </div>
    </div>
</template>

<script>
import loading from '../loading.vue';
export default {
    components: {
        loading
    },
    data() {
        return {
            name: '',
            whatsapp: '',
            telegram: '',
            email: '',
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        add() {
            
            if (this.name == '' || this.whatsapp == '' || this.telegram == '' || this.email == '') {
                this.$toast.warning('Make sure all field are completed', '');
                return
            }

            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('whatsapp', this.whatsapp)
            form.append('telegram', this.telegram)
            form.append('email', this.email)

            this.$http.post('/pastor/add', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    let pastor = {
                        'name': this.name,
                        'whatsapp': this.whatsapp,
                        'telegram': this.telegram,
                        'email': this.email,
                        'id': this.id
                    }
                    
                    this.$toast.success('A new pastor has been added', 'Added', this.$root.$data.success)

                    this.$emit('new_pastor', pastor)
                    this.loading = false
                }
            })
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        background-color: white;
        border-radius: 5px;
        width: 300px;
        position: relative;
        z-index: 3;
        padding: 20px;
        animation: pop .3s;
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        font-weight: 500;
        font-size: 14px;
        animation: up .2s;
    }
    @keyframes up {
        from {
            top: 10px;
            opacity: 0;
        }
        to {
            top: -10px;
            opacity: 1;
        }
    }
    input {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }
</style>