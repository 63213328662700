<template>
    <div class="each">
        <div class="text">{{i + 1}}</div>
        <div class="text">{{pastor.name}}</div>
        <div class="text">{{pastor.whatsapp}}</div>
        <div class="text">{{pastor.telegram}}</div>
        <div class="text">{{pastor.email}}</div>
        <div class="option" @click="pop = true">
            <optionicon :size="'20px'"/>
        </div>

        <div class="popup" v-if="pop">
            <div class="dark" @click="close()"></div>
            <div class="box">
                <div class="title">Remove Pastor</div>
                <div class="t">
                    Are you sure you want to remove this pastor from this broadcast ({{pastor.name}})
                </div>

                <loading :height="'37px'" v-if="loading"/>
                <div class="btn" v-else @click="remove()">Delete</div>
            </div>
        </div>
    </div>
</template>

<script>
import optionicon from '../../icons/close.vue'
import loading from '../loading.vue';

export default {
    components: {
        optionicon, loading
    },
    props: ['pastor', 'i', 'id'],
    data() {
        return {
            loading: false,
            pop: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.pop = false
        },
        remove() {
            let form = new FormData()
            form.append('ids', JSON.stringify([this.pastor.id]))
            form.append('id', this.id)

            this.loading = true

            this.$http.post('/broadcast/pastors/remove', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$toast.success('pastors has been removed', 'Removed', this.$root.$data.success)
                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                }
            })
        }
    }
}
</script>

<style scoped>

    .each {
        display: grid;
        grid-template-columns: 10% 20% 20% 20% 25% 5%;
        border-top: 1px solid #ccc;
        padding: 15px 20px;
    }
    .option {
        display: flex;
        width: 100%;
        justify-content: center;
        cursor: pointer;
        stroke: black;
        stroke-width: 3px;
    }

    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        background-color: white;
        border-radius: 5px;
        width: 300px;
        position: relative;
        z-index: 3;
        padding: 20px;
        animation: pop .3s;
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .t {
        margin-bottom: 20px;
        font-size: 14px;
        color: gray;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }
</style>