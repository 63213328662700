<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Delete Template</div>
            <div class="text">
                Are you sure you want to delete this template ({{label}})
            </div>

            <loading :height="'37px'" v-if="loading"/>
            <div class="btn" v-else @click="remove()">Delete</div>
        </div>
    </div>
</template>

<script>
import loading from '../loading.vue';
export default {
    components: {
        loading
    },
    props: ['label', 'id'],
    data() {
        return {
            loading: false
        }
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        remove() {
            this.loading = true

            let form = new FormData()
            form.append('id', this.id)

            this.$http.post('/template/delete', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.$toast.success('Template removed', 'Deleted', this.$root.$data.success)
                    
                    this.loading = false
                    this.$router.go()
                }
            })
        }
    }
}
</script>

<style scoped>


    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        background-color: white;
        border-radius: 5px;
        width: 300px;
        position: relative;
        z-index: 3;
        padding: 20px;
        animation: pop .3s;
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .text {
        margin-bottom: 20px;
        font-size: 14px;
        color: gray;
    }


    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }
</style>