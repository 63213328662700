<template>
    <div class="popup">
        <div class="dark" @click="close()"></div>
        <div class="box">
            <div class="title">Edit Broadcast</div>
            <div class="input">
                <div class="label" v-if="title != ''">Title</div>
                <input placeholder="Edit broadcast title" v-model="t"/>
            </div>
            
            <loading :height="'37px'" v-if="loading"/>
            <div class="btn" v-else @click="save()">Save</div>
        </div>
    </div>
</template>

<script>
import loading from '../loading.vue'
export default {
    components: {
        loading
    },
    props: ['id', 'title'],
    data() {
        return {
            t: '',
            
            loading: false
        }
    },
    mounted() {
        this.t = this.title
    },
    methods: {
        close() {
            if (this.loading) { return }
            this.$emit('close')
        },
        save() {
            if (this.t == '') {
                this.$toast.warning('Make sure all field are completed', '');
                return
            }
            this.loading = true

            let form = new FormData()
            form.append('id', this.id)
            form.append('name', this.t)

            this.$http.post('/broadcast/edit', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    
                    this.$toast.success('Droadcast have been edited', 'Edited', this.$root.$data.success)
                    this.loading = false
                    
                    this.$router.go()
                }
            })
        }
    }

}
</script>

<style scoped>

    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    .dark {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .box {
        background-color: white;
        border-radius: 5px;
        width: 300px;
        position: relative;
        z-index: 3;
        padding: 20px;
        animation: pop .3s;
    }
    @keyframes pop {
        from {
            transform: scale(.7);
        }
        to {
            transform: scale(1);
        }
    }
    .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }

    .input {
        margin-bottom: 20px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        padding: 0px 5px;
        background-color: white;
        font-weight: 500;
        font-size: 14px;
        animation: up .2s;
    }
    @keyframes up {
        from {
            top: 10px;
            opacity: 0;
        }
        to {
            top: -10px;
            opacity: 1;
        }
    }
    input {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }
</style>