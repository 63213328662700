<template>
    <div class="calendarpage">
        <div class="head">
            <div class="title">
                Calendar
            </div>
            <div class="small">
                This calendar shows the celebration dates of the glorious church
            </div>
        </div>

        <div class="main">
            <div class="calendar">
                <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
            </div>
            <div>
                <div class="events" ref="events">
                    <div class="t">{{month}} {{year}} Events</div>

                    <div class="loader" v-if="fetching">
                        <loader/>
                    </div>

                    <div class="empty" v-if="events.length == 0 && !fetching">
                        <calendar :size="'30px'"/>
                        <div class="e_text">You have not added any event </div>
                    </div>

                    <div class="event" v-for="(item, i) in events" :key="i" 
                        :style="{'background-color': item.backgroundColor}" 
                        :data-event="JSON.stringify(item)">
                        {{item.title}}
                    </div>
                </div>
                <div class="create">
                    <div class="t">Create an event</div>
                    <div class="colors">
                        <div class="color" v-for="color in colors" :key="color" @click="selected = color"
                            :style="{'background-color': color}" 
                            :class="[selected == color ? 'a': '']"></div>
                    </div>
                    <input type="text" placeholder="Enter event title" v-model="title">

                    <loading :height="'37px'" v-if="loading"/>
                    <div class="btn" @click="add()" v-else>Add</div>
                </div>
            </div>
        </div>

        <div class="spining" v-if="updating">
            <loader/>
        </div>

    </div>
</template>

<script>

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'

import moment from 'moment'

import calendar from '../../icons/calendar.vue'

import loading from '../../components/loading.vue'
import loader from '../../components/loader.vue'

export default {
    components: {
        FullCalendar,
        calendar,
        loading, loader
    },
    mounted() {
        new Draggable(this.$refs.events, {
            itemSelector: '.event'
        });

        this.date = moment().format('YYYY-MM-DD')
        this.month = new moment().format('MMMM')
        this.year = new moment().format('YYYY')

        this.fetchEvents(this.month, this.year)

        
        this.$http.get('/calendar/get')
        .then(data => {
            let r = data.data

            if (r.success) {
                this.calendarOptions.events = r.calendar

                this.fetching = false
            }
        })
    },
    data() {
        return {
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                showNonCurrentDates: false,
                fixedWeekCount: false,
                dayMaxEventRows: 3,
                droppable: true,
                editable: true,
                events: [],
                eventClick: (info) => {
                    console.log(info.event);
                },
                eventReceive: ( info ) => {
                    this.add_to_calendar(info)
                },
                eventDrop: (info) => {
                    this.edit_calendar(info)
                },
                customButtons: {
                    prevButton: {
                        icon: 'chevron-left',
                        click: () => {
                            this.date = moment(this.date).subtract(1, 'M').format('YYYY-MM-DD')
                            
                            this.month = new moment(this.date).format('MMMM')
                            this.year = new moment(this.date).format('YYYY')
                            this.fetchEvents(this.month, this.year)
                            
                            this.$refs.fullCalendar.getApi().prev()
                        }
                    },
                    nextButton: {
                        icon: 'chevron-right',
                        click: () => {
                            this.date = moment(this.date).add(1, 'M').format('YYYY-MM-DD')
                            
                            this.month = new moment(this.date).format('MMMM')
                            this.year = new moment(this.date).format('YYYY')
                            this.fetchEvents(this.month, this.year)

                            this.$refs.fullCalendar.getApi().next()
                        }
                    }
                },
                headerToolbar: {
                    start: 'prevButton',
                    center: 'title',
                    end: 'nextButton'
                }
            },
            events: [],
            colors: [
                'purple', 'tomato', 'royalblue', 'orange', 'gold', 'black', 'teal', 'olive', 'coral',
                'brown', 'grey'
            ],
            selected: '',

            date: '',
            year: '',
            month: '',

            title: '',

            loading: false,
            fetching: true,
            updating: false
        }
    },
    methods: {
        fetchEvents(month, year) {

            this.fetching = true
            this.events = []

            let form = new FormData()
            form.append('month', month)
            form.append('year', year)

            this.$http.post('/events/get', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.events = r.events
                }

                this.fetching = false
            })
        },
        add() {
            if ( this.title == '' ) { 
                this.$toast.warning('Event title cannot be empty', '');
                return 
            }
            if ( this.selected == '' ) {
                this.$toast.warning('Add a color to your event');
                return
            }
            this.loading = true

            let form = new FormData()
            form.append('month', this.month)
            form.append('year', this.year)
            form.append('title', this.title)
            form.append('color', this.selected)

            this.$http.post('/event/new', form)
            .then( data => {
                let r = data.data

                if (r.success) {
                    this.events.push({
                        'title': this.title,
                        'year': this.year,
                        'month': this.month,
                        'backgroundColor': this.selected,
                        'borderColor': this.selected,
                        'id': r.id
                    })
                    this.title = ''
                    this.selected = ''
                    this.loading = false
                    this.$toast.success('A new event has been added', 'Added', this.$root.$data.success)
                }
            })
        },
        add_to_calendar(info) {

            info.revert()
            this.updating = true

            let form = new FormData()
            form.append('title', info.event.title)
            form.append('start', moment(info.event.start).format('YYYY-MM-DD'))
            form.append('color', info.event.backgroundColor)

            this.$http.post('/calendar/new', form)
            .then( data => {
                let r = data.data
                if (r.success) {
                    // this.$router.go()
                    this.calendarOptions.events.push({
                        'title': info.event.title,
                        'start': moment(info.event.start).format('YYYY-MM-DD'),
                        'backgroundColor': info.event.backgroundColor,
                        'borderColor': info.event.backgroundColor,
                        'id': r.id
                    })
                    
                    this.updating = false
                }
            })
        },
        edit_calendar(info) {
            
            this.updating = true

            let form = new FormData()
            form.append('start', moment(info.event.start).format('YYYY-MM-DD'))
            form.append('id', info.event.id)

            this.$http.post('/calendar/edit', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$toast.success('Event date has been edited', 'Edited', this.$root.$data.success)
                } else {
                    info.revert()
                }

                this.updating = false
            })
        }
    }
}
</script>

<style scoped>

    .calendarpage {
        padding: 30px;
        position: relative;
    }
    .head {
        /* display: flex; */
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-size: 25px;
        font-weight: 500;
    }
    .small {
        font-size: 14px;
        color: gray;
    }

    .main {
        display: grid;
        grid-template-columns: auto 300px;
        grid-gap: 30px;
        margin-top: 30px;
    }
    .calendar {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        height: max-content;
    }

    .events {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
    }
    .t {
        font-weight: 500;
        margin-bottom: 20px;
        font-size: 18px;
    }
    .loader {
        margin: 50px 0px;
    }

    .empty {
        fill: var(--main);
        color: gray;
        text-align: center;
        font-size: 14px;
        margin: 30px 0px;
        margin-top: 40px;
    }
    .e_text {
        margin-top: 5px;
    }


    .event {
        padding: 10px 20px;
        border-radius: 5px;
        color: white;
        font-weight: 500;
        margin-top: 10px;
        cursor: pointer;
    }

    .create {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        margin-top: 30px;
    }
    .colors {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 20px;
    }
    .color {
        width: 25px;
        height: 25px;
        border-radius: 50px;
        cursor: pointer;
        transition: border-radius .3s;
    }
    .a {
        border-radius: 2px;
    }
    input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
    }


    .spining {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
    }


    @media screen and (max-width: 750px) {
        .main {
            grid-template-columns: auto;
        }
    }
</style>