<template>

    <div class="broadcast" :class="[show ? 'show' : '']">
        <div class="flex" @click="show = !show">
            <div class="text">{{broadcast.name}}</div>
            <div class="no">{{broadcast.total}}</div>
            <div class="arr">
                <arrow :size="'20px'" class="a" :class="[show ? 'rot' : '']"/>
            </div>
        </div>
        <broadcast v-if="show" :pastors="broadcast.pastors" :id="broadcast.id" 
            :title="broadcast.name"/>
    </div>
</template>

<script>
import arrow from '../../icons/arrow.vue'
import broadcast from './table.vue'

export default {
    props: ['broadcast'],
    components: {
        arrow,
        broadcast
    },
    data() {
        return {
            show: false
        }
    }
}
</script>

<style scoped>

    .broadcast {
        background-color: white;
        margin-top: 30px;
        border-radius: 5px;
        overflow: hidden;
    }

    .show .flex {
        background-color: var(--main);
        color: white;
        fill: white;
    }
    .show .no {
        background-color: white;
        color: var(--main);
    }


    .flex {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px;
        cursor: pointer;
        transition: .3s;
    }
    .text {
        font-weight: 500;
        font-size: 18px;
    }
    .no {
        font-weight: 500;
        background-color: var(--main);
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: white;
    }
    .arr {
        margin-left: auto;
    }
    .a {
        transition: .3s;
    }
    .rot {
        transform: rotate(90deg);
    }
</style>