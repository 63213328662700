<template>
    <div class="t">
        <div class="table">
            <div class="head">
                <div class="text">No</div>
                <div class="text">Name</div>
                <div class="text">Whatsapp</div>
                <div class="text">Telegram</div>
                <div class="text">Email</div>
                <div class="flex">
                    <div class="btn" @click="add = true">Add</div>
                    <div class="icon">
                        <div @click="opt = !opt">
                            <optionicon :size="'20px'"/>
                        </div>
                        <div class="opt" v-if="opt">
                            <optionbox 
                                @edit="edit=true; opt = false"
                                @del="del=true; opt = false"/>
                        </div>
                    </div>
                </div>
            </div>
            <each v-for="(pastor, i) in pastors" :key="pastor.id" :i="i" :pastor="pastor" :id="id"/>

            <div class="empty" v-if="pastors.length == 0">
                <pastorsicon :size="'50px'"/>
                <div class="em_text">No pastor has been added to this broadcast</div>
            </div>

            <add v-if="add" @close="add = false" :id="id" :old="pastors"/>

            <edit :id="id" :title="title" v-if="edit" @close="edit = false"/>
            <deletebox :name="title" :id="id" v-if="del" @close="del = false"/>
        </div>
    </div>
</template>

<script>
import each from './each.vue'
import add from './add.vue'
import optionbox from './option.vue'
import edit from './edit.vue'
import deletebox from './delete.vue'

import pastorsicon from '../../icons/pastors.vue'
import optionicon from '../../icons/option.vue'



export default {
    components: {
        each, add, optionbox,
        edit, deletebox,
        pastorsicon, optionicon
    },
    props: ['pastors', 'id', 'title'],
    data() {
        return {
            add: false,
            opt: false,
            edit: false,
            del: false
        }
    },
}
</script>

<style scoped>
    .t {
        overflow: auto;
    }
    .table {
        min-width: 900px;
        /* background-color: white; */
        /* border-top: 1px solid #ccc; */
    }

    .head {
        display: grid;
        grid-template-columns: 10% 20% 20% 20% 10% 20%;
        align-items: center;
        /* border-bottom: 1px solid #ccc; */
        padding: 15px 20px;
        font-weight: 500;
    }

    .flex {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .btn {
        padding: 5px 20px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        font-size: 12px;
        border-radius: 5px;
        cursor: pointer;
        width: fit-content;
        margin-left: auto;
    }
    .icon {
        cursor: pointer;
        position: relative;
    }
    
    .empty {
        text-align: center;
        fill: #555;
        padding: 80px 0px;
        border-top: 1px solid #ccc;
        font-size: 14px;
        color: gray;
    }
</style>