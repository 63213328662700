import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import notify from './externals/notify'


import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
Vue.use(VueQuillEditor)

import axios from 'axios'

Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://localhost/bcgnotify/index.php/'
axios.defaults.baseURL = 'https://bcgnotify.herokuapp.com/index.php/'
axios.defaults.headers.common['Auth'] = '';


import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';

var defaultOptionsObject = {
    timeout: 2000
}
Vue.use(VueIziToast, defaultOptionsObject);



Vue.config.productionTip = false

new Vue({
    router,
    // notify,
    data() {
        return {
            color: {
                main: 'royalblue',
                trans: 'rgba(65, 105, 225, 0.25)'
            },
            
            success: {
                transitionIn: 'fadeIn',
                color: 'royalblue',
                messageColor: '#fff',
                titleColor: '#fff',
                iconColor: 'white',
                theme: 'dark',
                progressBar: false
            },
            error: {
                color: 'red',
                transitionIn: 'bounceInLeft',
                // messageColor: '#fff',
                // titleColor: '#fff',
                // iconColor: 'white',
                // theme: 'dark',
                progressBar: false
            }
        }
    },
    render: h => h(App)
}).$mount('#app')
