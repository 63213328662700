<template>
    <div>
        <svg :height="size" :width="size" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <title/>
            <g>
                <line class="cls-1" x1="7" x2="25" y1="7" y2="25"/>
                <line class="cls-1" x1="7" x2="25" y1="25" y2="7"/>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['size']
}
</script>

<style scoped>

</style>