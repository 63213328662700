<template>
    <div class="home">
        <div class="head">
            <menulayout class="m"/>
            <img src="../assets/bcg.jpg" alt="">
            <!-- <div class="title">BCG Notify</div> -->
            <div class="logout" @click="logout()">Logout</div>
        </div>
        <div class="body">
            <div class="menu">
                <router-link to="/home/dashboard">
                    <div class="item" :class="[c == 'dashboard' ? 'a': '']">
                        <dashboard :size="'20px'" :style="{'height': '20px'}"/>
                        Dashboard
                    </div>
                </router-link>

                <router-link to="/home/pastors">
                    <div class="item" :class="[c == 'pastors' ? 'a': '']">
                        <pastors :size="'20px'" :style="{'height': '20px'}"/>
                        Pastors
                    </div>
                </router-link>

                <router-link to="/home/broadcast">
                    <div class="item" :class="[c == 'broadcast' ? 'a': '']">
                        <broadcast :size="'20px'" :style="{'height': '20px'}"/>
                        Broadcast
                    </div>
                </router-link>

                <router-link to="/home/calendar">
                    <div class="item" :class="[c == 'calendar' ? 'a': '']">
                        <calendar :size="'20px'" :style="{'height': '20px'}"/>
                        Calendar
                    </div>
                </router-link>

                <router-link to="/home/send">
                    <div class="item" :class="[c == 'send' ? 'a': '']">
                        <send :size="'20px'" :style="{'height': '20px'}"/> 
                        Send Message
                    </div>
                </router-link>
            </div>
            
            <div class="main">
                <router-view v-if="ready"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import dashboard from '../icons/dashboard.vue'
import pastors from '../icons/pastors.vue'
import broadcast from '../icons/broadcast.vue'
import calendar from '../icons/calendar.vue'
import send from '../icons/send.vue'

import menulayout from '../components/menu.vue'

import Localbase from 'localbase'
let db = new Localbase('bcgnotify')

export default {
    components: {
        dashboard, pastors, broadcast, calendar, send,
        menulayout
    },
    computed: {
        c() {
            return this.$route.name
        }
    },
    data() {
        return {
            ready: false,
        }
    },
    mounted() {
        db.collection('auth').doc({ id: 'auth' }).get().then(document => {
            if (document == undefined) {
                this.$router.push('/')
            } else {
                this.$http.defaults.headers.common['Auth'] = document.key

                setTimeout(() => {
                    this.ready = true
                }, 100);
            }
        })
    },
    methods: {
        logout() {
            
            db.collection('auth').doc({ id: 'auth' }).delete()
            this.$root.$data.login = false
            
            this.$http.defaults.headers.common['Auth'] = ''
            localStorage.clear()
            this.$router.push('/')
        }
    }
}
</script>

<style scoped>
    .head {
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        /* justify-content: space-between; */
        /* border-bottom: 1px solid #ccc; */
    }
    .m {
        display: none;
    }
    img {
        width: 50px;
    }
    .title {
        font-size: 23px;
        font-weight: 500;
        color: var(--main);
    }
    .logout {
        /* border: 2px solid var(--main); */
        margin-left: auto;
        background-color: var(--trans);
        color: var(--main);
        padding: 8px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
    }
    
    .body {
        display: grid;
        grid-template-columns: 300px auto;
        height: calc(100vh - 78px);
    }

    .menu {
        border-top: 1px solid #ddd;
    }
    .item {
        padding: 20px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #ddd;
        fill: #555;
    }
    .a {
        background-color: var(--main);
        color: white;
        fill: white;
        border-color: transparent;
    }
    .main {
        background-color: #edeef0;
        overflow: auto;
        height: 100%;
        border-top: 1px solid #ddd;
        box-shadow: inset rgba(0, 0, 0, 0.3) 0px 7px 9px -7px ;
    }


    @media screen and (max-width:900px) {
        .m {
            display: block;
        }
        .menu {
            display: none;
        }
        .body {
            grid-template-columns: auto;
        }
    }
</style>