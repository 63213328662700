<template>
    <div>
        <div class="icon" @click="show = !show">
            <menuicon :size="'20px'"/>
        </div>
        <div class="dark" v-if="show" @click="show = false"></div>

        <transition name="slide">
            <div class="menu" v-if="show">
                <router-link to="/home/dashboard">
                    <div class="item" :class="[c == 'dashboard' ? 'a': '']">
                        <dashboard :size="'20px'" :style="{'height': '20px'}"/>
                        Dashboard
                    </div>
                </router-link>

                <router-link to="/home/pastors">
                    <div class="item" :class="[c == 'pastors' ? 'a': '']">
                        <pastors :size="'20px'" :style="{'height': '20px'}"/>
                        Pastors
                    </div>
                </router-link>

                <router-link to="/home/broadcast">
                    <div class="item" :class="[c == 'broadcast' ? 'a': '']">
                        <broadcast :size="'20px'" :style="{'height': '20px'}"/>
                        Broadcast
                    </div>
                </router-link>

                <router-link to="/home/calendar">
                    <div class="item" :class="[c == 'calendar' ? 'a': '']">
                        <calendar :size="'20px'" :style="{'height': '20px'}"/>
                        Calendar
                    </div>
                </router-link>

                <router-link to="/home/send">
                    <div class="item" :class="[c == 'send' ? 'a': '']">
                        <send :size="'20px'" :style="{'height': '20px'}"/> 
                        Send Message
                    </div>
                </router-link>
            </div>
        </transition>
    </div>
</template>

<script>
import menuicon from '../icons/menu.vue';

import dashboard from '../icons/dashboard.vue'
import pastors from '../icons/pastors.vue'
import broadcast from '../icons/broadcast.vue'
import calendar from '../icons/calendar.vue'
import send from '../icons/send.vue'

export default {
    components: {
        menuicon,
        dashboard, pastors, broadcast, calendar, send,
    },
    data() {
        return {
            show: false
        }
    },
    computed: {
        c() {
            return this.$route.name
        }
    },
}
</script>

<style scoped>
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 9;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .menu {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 300px;
        height: 100%;
        z-index: 10;
        background-color: white;
    }
    
    .item {
        padding: 20px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid #ddd;
        fill: #555;
    }
    .a {
        background-color: var(--main);
        color: white;
        fill: white;
        border-color: transparent;
    }
</style>