<template>
    <div class="broadcasts">
        <div class="head">
            <div class="title">
                Broadcast
            </div>
            <div class="btn" @click="create = true">Create New Broadcast</div>
        </div>

        <loader class="loader" v-if="loading"/>
        <div v-else>
            <broadcast v-for="(broadcast, i) in broadcasts" :broadcast="broadcast" :key="i"/>
        </div>

        <div class="empty" v-if="!loading && broadcasts.length == 0">
            <broadcasticon :size="'50px'"/>
            <div class="em_text">No broadcast has been added to this database</div>
        </div>

        <createbox v-if="create" @close="create = false" @created="created($event)"/>
    </div>
</template>

<script>
import broadcast from '../../components/broadcast/view.vue'
import createbox from '../../components/broadcast/create.vue'

import loader from '../../components/loader.vue'
import broadcasticon from '../../icons/broadcast.vue'

export default {
    components: {
        broadcast, createbox,
        loader,
        broadcasticon
    },
    data() {
        return {
            create: false,
            broadcasts: [],
            loading: true
        }
    },
    mounted() {
        this.$http.get('/broadcast/all')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.broadcasts = r.broadcasts
                this.loading = false
            }
        })
    },
    methods: {
        created(e) {
            this.broadcasts.unshift(e)
            this.create = false
        }
    }
}
</script>

<style scoped>

    .broadcasts {
        padding: 30px;
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-size: 25px;
        font-weight: 500;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
    }


    .loader {
        margin-top: 150px;
    }

    
    .empty {
        text-align: center;
        fill: #555;
        padding: 80px 0px;
        font-size: 14px;
        color: gray;
    }
</style>