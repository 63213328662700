import { render, staticRenderFns } from "./loader.vue?vue&type=template&id=b4bb7e6e&scoped=true&"
import script from "./loader.vue?vue&type=script&lang=js&"
export * from "./loader.vue?vue&type=script&lang=js&"
import style0 from "./loader.vue?vue&type=style&index=0&id=b4bb7e6e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4bb7e6e",
  null
  
)

export default component.exports