<template>
    <div class="pastors">
        <div class="head">
            <div class="title">
                Pastors
            </div>
            <div class="btn" @click="show = true">Add a pastor</div>
        </div>
        <pastors :loading="loading" :pastors="pastors" @removed="removed($event)" @edited="edited($event)"/>

        <add v-if="show" @close="show = false" @new_pastor="add_passtor($event)"/>
    </div>
</template>

<script>
import pastors from '../../components/pastors/table.vue'
import add from '../../components/pastors/add.vue'

export default {
    components: {
        pastors, add
    },
    data() {
        return {
            show: false,
            loading: true,
            pastors: []
        }
    },
    mounted() {
        this.$http.get('/pastors/get')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.pastors = r.pastors

                this.loading = false
            }
        })
    },
    methods: {
        add_passtor(e) {
            this.pastors.unshift(e)
            this.show = false
        },
        removed(e) {
            console.log(e);
            this.pastors = this.pastors.filter(value => {
                console.log(value);
                return value.id != e
            })
        },
        edited(e) {
            console.log(e);
            this.pastors[e.i] = e.data

            console.log(this.pastors);
        }
    }
}
</script>

<style scoped>
    .pastors {
        padding: 30px;
        /* width: 100vw; */

    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-size: 25px;
        font-weight: 500;
    }
    .btn {
        padding: 8px 30px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
    }
</style>