<template>
    <div class="login">
        <div class="box">
            <div class="image">
                <img src="../assets/bcg.jpg" alt="">
            </div>
            <!-- <div class="title">BCG Notify - Login</div> -->
            <div class="text">Enter your username and password to login</div>

            <div class="input">
                <div class="label" v-if="username != ''">Username</div>
                <input type="text" placeholder="Enter your username" v-model="username">
            </div>

            <div class="input">
                <div class="label" v-if="password != ''">Password</div>
                <input type="password" placeholder="Enter yuor password" v-model="password">
            </div>

            <loading :height="'41px'" v-if="loading"/>
            <div class="btn" v-else @click="login()">Login</div>
        </div>
    </div>
</template>

<script>
import loading from '../components/loading.vue'

import Localbase from 'localbase'
let db = new Localbase('bcgnotify')

export default {
    name: 'Home',
    components: {
        loading
    },
    data() {
        return {
            username: '',
            password: '',
            loading: false
            
        }
    },
    mounted() {
        db.collection('auth').doc({ id: 'auth' }).get().then(document => {
            if (document == undefined) {
                return
            } else {
                this.$http.defaults.headers.common['Auth'] = document.key
                setTimeout(() => {
                    this.$router.push('/home/dashboard')
                }, 1000);
            }
        })
    },
    methods: {
        
        login() {
            if (this.username == '' || this.password == '') {
                this.$toast.warning('Enter username and password', '');
                return
            }
            this.loading = true
            let form = new FormData()
            form.append('username', this.username)
            form.append('password', this.password)

            this.$http.post('/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    
                    this.$toast.success('You are now logged in!', 'Success', this.$root.$data.success)
                    
                    db.collection('auth').add({
                        id: 'auth',
                        username: this.username,
                        key: r.session
                    }).then( () => {
                        this.$router.push('/home/dashboard')
                    })

                } else {
                    if (r.u) {
                        this.$toast.error('User not found', 'Username', this.$root.$data.error)
                    }
                    if (r.p) {
                        this.$toast.error('Incorrect password', 'Password', this.$root.$data.error)
                    }
                    this.loading = false
                }
            })
        }
    },
    notifications: {
        succ: {
            type: 'success',
            title: 'Success',
            message: 'Login success'
        }
    }
}
</script>

<style scoped>
    .login {
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #edeef0;

    }
    .box {
        width: 350px;
        padding: 30px;
        border-radius: 10px;
        background-color: white;
    }
    .image {
        text-align: center;
    }
    img {
        width: 80px;
        /* text-align: center; */
        /* margin: 0 auto; */
    }
    .title {
        font-size: 25px;
        font-weight: 500;
    }
    .text {
        color: gray;
        margin-top: 3px;
        margin-bottom: 30px;
        font-size: 12px;
        text-align: center;
    }

    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        background-color: white;
        font-size: 14px;
        font-weight: 500;
        padding: 0px 5px;
        animation: up .2s;
    }
    @keyframes up {
        from {
            top: 10px;
            opacity: 0;
        }
        to {
            top: -10px;
            opacity: 1;
        }
    }
    input {
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
        box-sizing: border-box;
    }

    .btn {
        padding: 10px;
        background-color: var(--main);
        text-align: center;
        border-radius: 5px;
        font-weight: 500;
        cursor: pointer;
        color: white;
    }
</style>