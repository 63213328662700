<template>
    <div id="app" :style="{'--main': c.main, '--trans': c.trans}">
        <router-view/>
    </div>
</template>

<script>
export default {
    computed: {
        c() {
            return this.$root.$data.color
        }
    }
}
</script>

<style>
    :root {
        --fc-button-bg-color: royalblue;
        --fc-button-border-color: royalblue;
    }
    body {
        padding: 0px;
        margin: 0px;
    }
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
    textarea {
        resize: none;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 14px;
    }
    

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
        min-height: 200px;
    }
    .ql-container.ql-snow {
        border: none !important;
    }
    .ql-toolbar.ql-snow {
        
        border-color: transparent !important;
        border-bottom-color: #ddd !important;
    }

    .fc-daygrid-event {
        border-radius: 0px !important;
    }
    .fc .fc-daygrid-event {
        margin-top: 3px !important;
    }
    .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title {
        padding: 3px !important;
    }
</style>
