<template>
    <div>
        <svg :height="size" viewBox="0 0 24 24" :width="size" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 
            0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 
            1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 
            0-1 1v5a1 1 0 0 0 1 1z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['size']
}
</script>

<style scoped>

</style>